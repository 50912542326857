import "../App.css";
import Navbar from "./Navbar";
import { NavLink } from "react-router-dom";

// import Home1 from "../Images/Home/Home1.png";
import Home2 from "../Images/Home/Home2.png";
import Home3 from "../Images/Home/Home3.png";
import Home4 from "../Images/Home/Home4.png";
import Home5 from "../Images/Home/Home5.png";
import Home6 from "../Images/Home/Home6.png";
import Home7 from "../Images/Home/Home7.png";
import Home8 from "../Images/Home/Home8.png";
import Home9 from "../Images/Home/Home9.png";
import Home10 from "../Images/Home/Home10.png";
import Home11 from "../Images/Home/Home11.png";
import Home12 from "../Images/Home/Home12.png";
import Home13 from "../Images/Home/Home13.png";
import Home14 from "../Images/Home/Home14.png";
import Home15 from "../Images/Home/Home15.png";
import Home16 from "../Images/Home/Home16.png";
import Home17 from "../Images/Home/Home17.png";
import Home18 from "../Images/Home/Home18.png";
import Home19 from "../Images/Home/Home19.png";
import Home20 from "../Images/Home/Home20.png";
import Home21 from "../Images/Home/Home21.png";
import Home22 from "../Images/Home/Home22.png";
import Home23 from "../Images/Home/Home23.png";
import Home24 from "../Images/Home/Home24.png";
import Home26 from "../Images/Home/Home26.png";
import Home27 from "../Images/Home/Home27.svg";
// import Home28 from "../Images/Home/Home28.png";
// import Home29 from "../Images/Home/Home29.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import CountUp from "react-countup";
import Footer from "./Footer";
import React, { useState, useEffect } from "react";

export default function Home() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  return (
    <div className="Home-page f-m-b loading">
      {loading ? (
        <div className="loader-container">
          {/*   <div class="loading-spinner"></div> */}
          <div class="loading-spinner"></div>
        </div>
      ) : (
        <div className="navbar-footer">
          <Navbar />
          <div className="home bg-color-w">
            {/* Home Banner Section Start */}
            <div className="home-banner">
              <OwlCarousel
                className="owl-theme"
                responsive={{
                  0: {
                    items: "1",
                  },
                  576: {
                    items: "1",
                  },
                  768: {
                    items: "1",
                  },
                  912: {
                    items: "1",
                  },
                  1000: {
                    items: "1",
                  },
                  1024: {
                    items: "1",
                  },
                  1366: {
                    items: "1",
                  },
                }}
                autoPlay={true}
                autoplayHoverPause={true}
                nav={false}
                center
                dots={true}
                autoplaySpeed={3000}
                loop={true}
                autoplayTimeout={1000}
              >
                <div className="item">
                  <div>
                    <div class="image-wrap">
                      <div class="card home-bg-card-1">
                        <div class="content">
                          <div class="carousel-caption d-none d-md-block">
                            <p>
                              <span className="border-line bg-color-w"></span>
                              Made in India
                            </p>
                            <h5>
                              Made for India <br /> Best UPS Solutions
                            </h5>
                            <NavLink to="/AboutUs">
                              <button>Read More</button>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div>
                    <div class="image-wrap">
                      <div class="card home-bg-card-2">
                        <div class="content">
                          <div class="carousel-caption d-none d-md-block">
                            <p>
                              <span className="border-line bg-color-w"></span>
                              Made in India
                            </p>
                            <h5>
                              Made for India <br /> Best UPS Solutions
                            </h5>
                            <NavLink to="/AboutUs">
                              <button>Read More</button>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div>
                    <div class="image-wrap">
                      <div class="card home-bg-card-3">
                        <div class="content">
                          <div class="carousel-caption d-none d-md-block">
                            <p>
                              <span className="border-line bg-color-w"></span>
                              Made in India
                            </p>
                            <h5>
                              Made for India <br /> Best UPS Solutions
                            </h5>
                            <NavLink to="/AboutUs">
                              <button>Read More</button>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
            {/* Home Banner Section End*/}

            {/* Products Section Start */}
            <div className="Products pt">
              <div className="container">
                <h3 className="header tac">
                  <span className="border-line bg-color-b"></span>Products
                </h3>
                <div className="row pt">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="cards">
                      <NavLink class="nav-link" to="/ServoStabilizer">
                        <div className="card-img card-img-1">
                          <img src={Home2} alt="" />
                        </div>
                        <div className="card-content">
                          <p className="m0">Servo Stabilizer</p>
                          <button>Read More</button>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="cards">
                      <NavLink class="nav-link" to="/ElectricalTransformer">
                        <div className="card-img">
                          <img src={Home3} alt="" />
                        </div>
                        <div className="card-content">
                          <p className="m0">Electrical Transformer</p>
                          <button>Read More</button>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="cards">
                      <NavLink class="nav-link" to="/Invertors">
                        <div className="card-img card-img-2">
                          <img src={Home4} alt="" />
                        </div>
                        <div className="card-content">
                          <p className="m0">Invertors</p>
                          <button>Read More</button>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="cards">
                      <NavLink class="nav-link" to="/UPS">
                        <div className="card-img card-img-4 card-img-2">
                          <img src={Home5} alt="" />
                        </div>
                        <div className="card-content">
                          <p className="m0">UPS</p>
                          <button>Read More</button>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="cards">
                      <NavLink class="nav-link" to="/Batteries">
                        <div className="card-img card-img-5">
                          <img src={Home6} alt="" />
                        </div>
                        <div className="card-content">
                          <p className="m0">Batteries</p>
                          <button>Read More</button>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="cards">
                      <NavLink class="nav-link" to="/OnlineUps">
                        <div className="card-img">
                          <img src={Home7} alt="" />
                        </div>
                        <div className="card-content">
                          <p className="m0">Online UPS</p>
                          <button>Read More</button>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="cards">
                      <NavLink class="nav-link" to="/LightingSolutions">
                        <div className="card-img">
                          <img src={Home8} alt="" />
                        </div>
                        <div className="card-content">
                          <p className="m0">Lighting solutions & LED Lights</p>
                          <button>Read More</button>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="cards">
                      <NavLink class="product-card" to="/SolarProducts">
                        <div className="card-img">
                          <img src={Home9} alt="" />
                        </div>
                        <div className="card-content">
                          <p className="m0">Solar products</p>
                          <button>Read More</button>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Products Section End */}
            {/* Why Choose Us? Section Start */}
            <div className="Why-Choose-Us">
              <div className="container">
                <h3 className="header tac">
                  <span className="border-line bg-color-w"></span>Why Choose Us?
                </h3>
                <p className="para tac">
                  We focus on customer satisfaction and delivers excellent
                  after-sales service for the products. We have a fully equipped
                  team of service professionals with vast industrial expertise
                  and in-depth knowledge in the field of solar power systems
                </p>

                <div className="client-section tac row pt">
                  <div className="col-lg-3 col-md-6 col-sm-12  card">
                    <img src={Home10} alt="" />
                    <h4>Quality Solutions</h4>
                    <p>
                      We are a certified ISO 9001:2015 company offering quality
                      green energy and power solutions
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 card">
                    <img src={Home11} alt="" />
                    <h4>Diverse Clients</h4>
                    <p>
                      We offer our solutions to a wide range of industries such
                      as Banks, Telecom & Media, IT Companies, Educational
                      Institutions & AMC etc.
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 card">
                    <img src={Home12} alt="" />
                    <h4>Reliable Service</h4>
                    <p>
                      Our technicians and customer support team are always ready
                      to assist you any time for any maintenance or help you
                      require.
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 card">
                    <img src={Home13} alt="" />
                    <h4>Quality Solutions</h4>
                    <p>
                      With more than two decades of industry experience, we
                      cater to the various power electronics requirement of our
                      customers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Why Choose Us? Section End */}
            {/* Client Count Up section Start */}
            <div className="counter-client tac pt">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-6">
                    <div
                      class="counter"
                      data-aos-delay="100"
                      data-aos="fade-down"
                      data-aos-easing="linear"
                    >
                      <CountUp
                        className="CountUp"
                        start={0}
                        end={25}
                        duration={6}
                        ref={(countUp) => {
                          this.myCountUp = countUp;
                        }}
                      />
                      <span className="plus">+</span>
                      <p>Successful Years</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-6">
                    <div
                      class="counter"
                      data-aos-delay="150"
                      data-aos="fade-down"
                      data-aos-easing="linear"
                    >
                      <CountUp
                        className="CountUp"
                        start={0}
                        end={1000}
                        duration={4}
                        ref={(countUp) => {
                          this.myCountUp = countUp;
                        }}
                      />
                      <span className="plus">+</span>
                      <p>Happy Customer</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-6">
                    <div
                      class="counter"
                      data-aos-delay="200"
                      data-aos="fade-down"
                      data-aos-easing="linear"
                    >
                      <CountUp
                        className="CountUp"
                        start={0}
                        end={100}
                        duration={10}
                        ref={(countUp) => {
                          this.myCountUp = countUp;
                        }}
                      />
                      <span className="plus">+</span>
                      <p>Installation in MW</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-6">
                    <div
                      class="counter"
                      data-aos-delay="250"
                      data-aos="fade-down"
                      data-aos-easing="linear"
                    >
                      <CountUp
                        className="CountUp"
                        start={0}
                        end={25}
                        duration={7}
                        ref={(countUp) => {
                          this.myCountUp = countUp;
                        }}
                      />
                      <span className="plus">+</span>
                      <p>Brand Associated</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Client Count Up section End */}
            {/* Our Services Section Start */}
            <div className="Our-Services pt">
              <div className="container">
                <h3 className="header tac cw">
                  <span className="border-line bg-color-w"></span>Our Services
                </h3>
                <div className="row cw tac pt">
                  <div className="col-lg-4 col-md-4 col-sm-12 br">
                    <div className="div-img">
                      <img src={Home14} alt="" />
                    </div>
                    <div>
                      <h4>Electrical Contractor</h4>
                      <p>
                        Quality and steady power is the main requirement in a
                        corporate environment. Our UPS systems are widely used
                        by corporates anywhere.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 br">
                    <div className="div-img">
                      <img src={Home15} alt="" />
                    </div>
                    <div>
                      <h4>Toll Gate Construction</h4>
                      <p>
                        We provide economical and undisturbed power solutions
                        that include stable and pure power to the hospitals day
                        & night with our wide range of Low Frequency Online UPS.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="div-img">
                      <img src={Home16} alt="" />
                    </div>
                    <div>
                      <h4>Engineering Consultant</h4>
                      <p>
                        Our solar power systems provide multiple benefits to
                        petrol pumps – Huge savings on operational cost, service
                        tax benefits & environmental benefits.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Our Services Section End */}

            {/* Brand Associated Section Start */}
            <div
              className="Brand-Associated tac pt"
              style={{
                background: "#e7e7e7",
              }}
            >
              <div className="container">
                <h3 className="header tac">
                  <span className="border-line bg-color-b"></span>Brand
                  Associated
                </h3>
                <p className="para">
                  Our program offers clear paths for our channel partners by
                  leveraging price and benefits providing better visibility and
                  unrelenting support.
                </p>
                <div
                  className="row pt mt mb d-flex justify-content-center"
                  // style={{
                  //   background: "#cccccc6b",
                  //   borderRadius: "10px",
                  // }}
                >
                  <div className="col-lg-3 col-md-6 col-12">
                    <img src={Home24} alt="exide" />
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    <img src={Home22} alt="amaron" />
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    <img src={Home20} alt="sf" />
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    <img src={Home17} alt="microtek" />
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    <img src={Home18} alt="luminous" />
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    <img src={Home27} alt="apc" />
                  </div>

                  <div className="col-lg-3 col-md-6 col-12">
                    <img src={Home19} alt="emersion" />
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    <img src={Home26} alt="luker" />
                  </div>

                  <div className="col-lg-3 col-md-6 col-12">
                    <img src={Home23} alt="panasonic" />
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    <img src={Home21} alt="numeric" />
                  </div>
                </div>
              </div>
            </div>
            {/* Brand Associated Section End */}
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
}
