import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  // HashRouter,
} from "react-router-dom";
import Navbar from "./Components/Navbar";
import Home from "./Components/Home";
import AboutUs from "./Components/AboutUs";
import Footer from "./Components/Footer";
import Gallery from "./Components/Gallery";
import Services from "./Components/Services";
import OnlineUps from "./Components/Products/OnlineUps";
import ContactUs from "./Components/ContactUs";
import ServoStabilizer from "./Components/Products/ServoStabilizer";
import Amc from "./Components/Products/Amc";
import ElectricalTransformer from "./Components/Products/ElectricalTransformer";
import UPS from "./Components/Products/UPS";
import SolarProducts from "./Components/Products/SolarProducts";
import Invertors from "./Components/Products/Invertors";
import Batteries from "./Components/Products/Batteries";
import LightingSolutions from "./Components/Products/LightingSolutions";
import EnquiryForm from "./Components/EnquiryForm";
import Product from "./Components/Product";

export default function Routeslist() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/Navbar" element={<Navbar />} />
          <Route path="/Footer" element={<Footer />} />
          <Route path="/" element={<Home />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/ContactUs" element={<ContactUs />} />


          <Route path="/ServoStabilizer" element={<ServoStabilizer />} />
          <Route path="/ElectricalTransformer" element={<ElectricalTransformer />} />
          <Route path="/UPS" element={<UPS />} />
          <Route path="/Amc" element={<Amc />} />
          <Route path="/Invertors" element={<Invertors />} />
          <Route path="/Batteries" element={<Batteries />} />
          <Route path="/OnlineUps" element={<OnlineUps />} />
          <Route path="/SolarProducts" element={<SolarProducts />} />
          <Route path="/LightingSolutions" element={<LightingSolutions />} />
          <Route path="/EnquiryForm" element={<EnquiryForm />} />
          <Route path="/Product" element={<Product />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
