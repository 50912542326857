import "../App.css";
import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import About2 from "../Images/About/About2.png";
import About3 from "../Images/About/About3.png";
import About4 from "../Images/About/About4.png";
import About5 from "../Images/About/About5.png";
import About6 from "../Images/About/About6.png";
import star from "../Images/About/star.png";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import CountUp from "react-countup";
import Footer from "./Footer";

export default function AboutUs() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  return (
    <div className="AboutUs ">
      {loading ? (
        <div className="loader-container">
          <div class="loading-spinner"></div>
        </div>
      ) : (
        <div className="navbar-footer">
          <Navbar />
          <div className="padding-top"></div>
          <div className="AboutUspage pb bg-color-w f-m-b ">
            {/* AboutUs-Pages Section Start */}
            <div className="aboutus-pages1 tac">
              <div className="contaienr">
                <h4 className="home-header">
                  About Power Guard Batteries and <br /> Lighting Concepts
                </h4>
              </div>
            </div>
            {/* AboutUs-Pages Section End */}

            {/* AboutUs Section Start */}
            <div className="about-section tac pt">
              <div className="container">
                <h2 className="blue-header">About</h2>
                <p className="header-para pt">
                  Power Guard System is a Manufacturing company started in the
                  year of 1998 as a proprietary concern. As a small
                  scaleindustry we are manufacturing all type of Transformers,
                  Voltage stabilizers, CVT, UPS, Electrical panels , Power
                  factor controllers, Oil cooled model stabilizers for
                  Industrial applications, Step-Down and Isolation Transformers
                  for Imported specialized equipments.
                </p>
                <div className="row pt aic Mission">
                  <div
                    className="col-lg-6"
                    style={{
                      padding: "0",
                    }}
                  >
                    <img src={About2} alt="" />
                  </div>
                  <div className="col-lg-6 ">
                    <h3>Mission</h3>
                    <p>
                      Our Mission is to add value to our customers with cost
                      effective, energy efficient, innovative, and reliable
                      power backup. We also adds on power conditioning products,
                      services and solutions. We are obsessed with Quality of
                      products and services to ensure best in class solutions
                      delivery to our Customers. Hope our quality solar products
                      with affordable price will help to gain more market and
                      win more reputation from our clients.
                    </p>
                  </div>
                </div>
                <div className="row aic Vission">
                  <div className="col-lg-6 ">
                    <h3>Vision</h3>
                    <p>
                      We work as united Team with united Aim to establish
                      SunGarner as the Premier Company in the Market. Every team
                      member will encourage, challenge constructively and
                      support each other to achieve our Vision. We focus on our
                      goals and achieve them with passion, meticulous planning,
                      accurate and faster execution, taking full ownership for
                      results. SunGarner’s growth provides growth platform for
                      every deserving individual associated with the company –be
                      it employees, business partners and suppliers.
                    </p>
                  </div>
                  <div
                    className="col-lg-6"
                    style={{
                      padding: "0",
                    }}
                  >
                    <img src={About3} alt="" />
                  </div>
                </div>
              </div>
            </div>
            {/* AboutUs Section End */}

            {/* Client Count Up section Start */}
            <div className="counter-client tac pt mt">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-6">
                    <div
                      class="counter"
                      data-aos-delay="100"
                      data-aos="fade-down"
                      data-aos-easing="linear"
                    >
                      <CountUp
                        className="CountUp"
                        start={0}
                        end={25}
                        duration={6}
                        ref={(countUp) => {
                          this.myCountUp = countUp;
                        }}
                      />
                      <span className="plus">+</span>
                      <p>Successful Years</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-6">
                    <div
                      class="counter"
                      data-aos-delay="150"
                      data-aos="fade-down"
                      data-aos-easing="linear"
                    >
                      <CountUp
                        className="CountUp"
                        start={0}
                        end={1000}
                        duration={4}
                        ref={(countUp) => {
                          this.myCountUp = countUp;
                        }}
                      />
                      <span className="plus">+</span>
                      <p>Happy Customer</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-6">
                    <div
                      class="counter"
                      data-aos-delay="200"
                      data-aos="fade-down"
                      data-aos-easing="linear"
                    >
                      <CountUp
                        className="CountUp"
                        start={0}
                        end={100}
                        duration={10}
                        ref={(countUp) => {
                          this.myCountUp = countUp;
                        }}
                      />
                      <span className="plus">+</span>
                      <p>Installation in MW</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-6">
                    <div
                      class="counter"
                      data-aos-delay="250"
                      data-aos="fade-down"
                      data-aos-easing="linear"
                    >
                      <CountUp
                        className="CountUp"
                        start={0}
                        end={25}
                        duration={7}
                        ref={(countUp) => {
                          this.myCountUp = countUp;
                        }}
                      />
                      <span className="plus">+</span>
                      <p>Brand Associated</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Client Count Up section End */}

            {/* Our Satisfied Clients Section Start */}
            <div className="Our-Satisfied-Clients pt">
              <div className="container">
                <div className="row aic">
                  <div className="col-lg-6">
                    <h3 className="tac">Our Satisfied Clients</h3>
                    <p className="tac">
                      some of our great clients and their review
                    </p>

                    <OwlCarousel
                      className="owl-theme"
                      responsive={{
                        0: {
                          items: "1",
                        },
                        576: {
                          items: "1",
                        },
                        768: {
                          items: "1",
                        },
                        912: {
                          items: "1",
                        },
                        1000: {
                          items: "1",
                        },
                        1024: {
                          items: "1",
                        },
                        1366: {
                          items: "1",
                        },
                      }}
                      autoPlay={true}
                      autoplayHoverPause={true}
                      nav={false}
                      center
                      dots={true}
                      autoplaySpeed={1000}
                      loop
                    >
                      <div className="item">
                        <div>
                          <div class="image-wrap">
                            <div class="card bg-card-1">
                              <div class="content">
                                <div className="star">
                                  <img src={star} alt="" />
                                  <img src={star} alt="" />
                                  <img src={star} alt="" />
                                  <img src={star} alt="" />
                                  <img src={star} alt="" />
                                </div>
                                <p class="copy">
                                  I had purchased a product lift inverter
                                  fromenertech and they supply it with a
                                  precision package and the product is really
                                  good. Appriciated their work.
                                </p>
                                <img
                                  src={About6}
                                  className="double-quoute"
                                  alt=""
                                />
                                <div className="client-name">
                                  <img src={About4} alt="" />
                                  <div>
                                    <h6>Vinod agarwal</h6>
                                    <p>Managing Director</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div>
                          <div class="image-wrap">
                            <div class="card bg-card-1">
                              <div class="content">
                                <div className="star">
                                  <img src={star} alt="" />
                                  <img src={star} alt="" />
                                  <img src={star} alt="" />
                                  <img src={star} alt="" />
                                  <img src={star} alt="" />
                                </div>
                                <p class="copy">
                                  I had purchased a product lift inverter
                                  fromenertech and they supply it with a
                                  precision package and the product is really
                                  good. Appriciated their work.
                                </p>
                                <img
                                  src={About6}
                                  className="double-quoute"
                                  alt=""
                                />
                                <div className="client-name">
                                  <img src={About4} alt="" />
                                  <div>
                                    <h6>Vinod agarwal</h6>
                                    <p>Managing Director</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div>
                          <div class="image-wrap">
                            <div class="card bg-card-1">
                              <div class="content">
                                <div className="star">
                                  <img src={star} alt="" />
                                  <img src={star} alt="" />
                                  <img src={star} alt="" />
                                  <img src={star} alt="" />
                                  <img src={star} alt="" />
                                </div>
                                <p class="copy">
                                  I had purchased a product lift inverter
                                  fromenertech and they supply it with a
                                  precision package and the product is really
                                  good. Appriciated their work.
                                </p>
                                <img
                                  src={About6}
                                  className="double-quoute"
                                  alt=""
                                />
                                <div className="client-name">
                                  <img src={About4} alt="" />
                                  <div>
                                    <h6>Vinod agarwal</h6>
                                    <p>Managing Director</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </OwlCarousel>
                  </div>
                  <div className="col-lg-6">
                    <img src={About5} alt="" />
                  </div>
                </div>
              </div>
            </div>
            {/* Our Satisfied Clients Section End */}
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
}
