import "../App.css";
import React, { useState, useEffect } from "react";

export default function EnquiryForm() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "RequestForm";
  }, []);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    product: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phone: "",
    product: "",
    message: "",
  });

  const [phoneNumber, setPhoneNumber] = useState("");
  const [valid, setValid] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });

    const inputValue = e.target.value;
    const numbersOnly = /^\d+$/;

    if (inputValue === "" || numbersOnly.test(inputValue)) {
      setValid(true);
      setPhoneNumber(inputValue);
    } else {
      setValid(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = {};

    // Validate name
    if (formData.name.trim() === "") {
      errors.name = "Please Enter your Name is required !";
    }

    // Validate phone
    if (formData.phone.trim() === "") {
      errors.phone = "Please Enter Your Phone Number is required !";
    } else if (!/\S+@\S+\.\S+/.test(formData.phone)) {
      errors.phone = "Please Enter Your Phone Number is invalid !";
    }
    // Validate message
    if (formData.message.trim() === "") {
      errors.message = "Message is required !";
    }
    // Validate product
    if (formData.product.trim() === "") {
      errors.product = "Please Select your Product is required !";
    }

    // Validate email
    if (formData.email.trim() === "") {
      errors.email = "Please Enter your Email ID is required !";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Please Enter your Email ID is invalid !";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      // Form submission logic here
      console.log("Form submitted successfully");
    }
  };

  return (
    <div className="RequestsForms">
      <button
        type="button"
        class="request-btn"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        data-aos="flip-left"
        data-aos-delay="800"
        data-aos-easing="linear"
      >
        Request Quote
      </button>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <div className="Contact-Form">
                <div className="container">
                  <h1
                    style={{
                      padding: "5px",
                    }}
                  >
                    Get Quote
                  </h1>
                  <p className="header-content">
                    Complete the form below with your enquiry and a member of
                    the Liftout team will conatct you
                  </p>
                  <form onSubmit={handleSubmit} id="contactForm">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Please Enter Your Name"
                            value={formData.name}
                            onChange={handleChange}
                          />
                          {formErrors.name && (
                            <p
                              className="error"
                              style={{
                                color: "red",
                                textAlign: "left",
                                paddingLeft: "20px",
                                fontSize: "12px",

                                margin: "0",
                              }}
                            >
                              {formErrors.name}
                            </p>
                          )}
                        </div>
                        <div>
                          <input
                            type="phone"
                            id="phone"
                            name="phone"
                            maxLength={10}
                            value={phoneNumber}
                            placeholder="Enter Your Phone Number"
                            onChange={handleChange}
                          />
                          {!valid && (
                            <p
                              style={{
                                color: "red",
                                textAlign: "left",
                                paddingLeft: "20px",
                                fontSize: "12px",

                                margin: "0",
                              }}
                            >
                              Please enter a valid phone number.
                            </p>
                          )}
                          {formErrors.phone && (
                            <p
                              className="error"
                              style={{
                                color: "red",
                                textAlign: "left",
                                paddingLeft: "20px",
                                fontSize: "12px",

                                margin: "0",
                              }}
                            >
                              {formErrors.phone}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Enter Your Email ID"
                            value={formData.email}
                            onChange={handleChange}
                          />
                          {formErrors.email && (
                            <p
                              className="error"
                              style={{
                                color: "red",
                                textAlign: "left",
                                paddingLeft: "20px",
                                margin: "0",
                                fontSize: "12px",
                              }}
                            >
                              {formErrors.email}
                            </p>
                          )}
                        </div>
                        <div>
                          <select>
                            <option
                              className="Selectproduct"
                              // value="Selectproduct"
                              type="text"
                              name="product"
                              id="product"
                              value={formData.product}
                              onChange={handleChange}
                            >
                              Select your product
                            </option>
                            <option value="Elevator">Elevator</option>
                            <option value="Escalator">Escalator</option>
                            <option value="Car parking solution">
                              Car parking solution
                            </option>
                          </select>
                          {formErrors.product && (
                            <p
                              className="error"
                              style={{
                                color: "red",
                                textAlign: "left",
                                paddingLeft: "20px",
                                margin: "0",
                                fontSize: "12px",
                              }}
                            >
                              {formErrors.product}
                            </p>
                          )}
                        </div>
                      </div>
                      <div>
                        <textarea
                          type="message"
                          id="message"
                          name="message"
                          rows="5"
                          cols="30"
                          placeholder="Please Text Your Message"
                          value={formData.message}
                          onChange={handleChange}
                        ></textarea>
                        {formErrors.message && (
                          <p
                            className="error"
                            style={{
                              color: "red",
                              textAlign: "left",
                              paddingLeft: "18.5%",
                              margin: "0",
                              fontSize: "12px",
                            }}
                          >
                            {formErrors.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div>
                      <button
                        class="btn btn-primary"
                        className="Submit"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
