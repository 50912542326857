import "../App.css";
import Navbar from "./Navbar";
import Gallery2 from "../Images/Gallery/Gallery2.png";
import Gallery3 from "../Images/Gallery/Gallery3.png";
import Gallery4 from "../Images/Gallery/Gallery4.png";
import Gallery5 from "../Images/Gallery/Gallery5.png";
import Gallery6 from "../Images/Gallery/Gallery6.png";
import Gallery7 from "../Images/Gallery/Gallery7.png";
import Gallery8 from "../Images/Gallery/Gallery8.png";
import Footer from "./Footer";
import React, { useState, useEffect } from "react";

export default function Gallery() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  return (
    <div className="Gallery ">
      {loading ? (
        <div className="loader-container">
          <div class="loading-spinner"></div>
        </div>
      ) : (
        <div className="navbar-footer">
          <Navbar />
          <div className="gallery-pages bg-color-w f-m-b">
            <div className="padding-top"></div>
            {/* Gallery Home Banner Start */}
            <div className="Gallery-home-banner">
              <h4 className="home-header">Gallery</h4>
            </div>
            {/* Gallery Home Banner End */}

            <div className="gallery-section tac pt">
              <div className="container">
                {/* <h2 className="blue-header">Gallery</h2> */}
                <p className="header-para pt">
                  PowerOne products have uses in different industries and
                  sectors of the economy. One must also not forget the
                  application of PowerOne products at the home-front. PowerOne
                  products can be used at the modern office, to power medical
                  equipment at hospitals, at petrol pumps and banks too!
                  PowerOne’s wide array of vibrant products can serve as the
                  backbone of your workplace, school or college, and home. With
                  everything from Transformers to Solar Panels, PowerOne
                  products find utility all across the board.
                </p>

                <div className="row">
                  <div className="col-lg-4 col-md-6 col-12">
                    <div class="container">
                      <div class="content">
                        <div class="content-overlay"></div>

                        <img src={Gallery2} alt="" class="content-image" />
                        <div class="content-details fadeIn-top">
                          <h3 class="content-title">Power Guard</h3>
                          <p class="content-text">Servo Voltage Stabiliser</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div class="container">
                      <div class="content">
                        <div class="content-overlay"></div>
                        <img src={Gallery3} alt="" class="content-image" />

                        <div class="content-details fadeIn-bottom">
                          <h3 class="content-title">Power Guard</h3>
                          <p class="content-text">
                            Servo Voltage Stabiliser <br /> <b>60 KVA</b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div class="container">
                      <div class="content">
                        <div class="content-overlay"></div>
                        <img src={Gallery4} alt="" class="content-image" />

                        <div class="content-details fadeIn-top">
                          <h3>Power Guard</h3>
                          <p class="content-text">
                            Servo Voltage Stabiliser <br /> <b>45 KVA</b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div class="container">
                      <div class="content">
                        <div class="content-overlay"></div>
                        <img src={Gallery5} alt="" class="content-image" />

                        <div class="content-details fadeIn-left">
                          <h3>Power Guard</h3>

                          <h3> </h3>
                          <p class="content-text">TransFormer</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div class="container">
                      <div class="content">
                        <div class="content-overlay"></div>
                        <img src={Gallery6} alt="" class="content-image" />

                        <div class="content-details fadeIn-right">
                          <h3 class="content-title">Power Guard</h3>
                          <p class="content-text">
                            Servo Voltage Stabiliser <br /> <b>60 KVA</b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div class="container">
                      <div class="content">
                        <div class="content-overlay"></div>
                        <img src={Gallery7} alt="" class="content-image" />

                        <div class="content-details fadeIn-top fadeIn-left">
                          <h3 class="content-title">Power Guard</h3>
                          <p class="content-text">
                            Servo Voltage Stabiliser <br /> <b>400 KVA</b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div class="container">
                      <div class="content">
                        <div class="content-overlay"></div>
                        <img src={Gallery8} alt="" class="content-image" />

                        <div class="content-details fadeIn-top fadeIn-right">
                          <h3 class="content-title">Power Guard</h3>
                          <p class="content-text">Electric Security Fence</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
      ;
    </div>
  );
}
