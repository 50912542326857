import "../App.css";
import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Product1 from "../Images/Product/Product1.jpg";
import Product2 from "../Images/Product/Product2.jpeg";
import Product3 from "../Images/Product/Product3.webp";
import Product4 from "../Images/Product/Product4.jpg";
import Product5 from "../Images/Product/Product5.png";
import Product6 from "../Images/Product/Product6.jpg";
import Product7 from "../Images/Product/Product7.png";
import Product8 from "../Images/Product/Product8.png";
export default function Product() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  return (
    <div className="Product ">
      {loading ? (
        <div className="loader-container">
            <div class="loading-spinner"></div>
        </div>
      ) : (
        <div className="navbar-footer">
          <Navbar />
          <div className="padding-top"></div>
          <div className="Product-page pb bg-color-w f-m-b ">
            {/* Product-Pages Section Start */}
            <div className="Product-Pages tac">
              <div className="contaienr">
                <h4 className="home-header">Product</h4>
              </div>
            </div>
            {/* Product-Pages Section End */}

            {/* Product deatils Section Start */}
            <div className="product-details">
              <div className="container">
                <h2 className="tac pt">Product Description</h2>
                <div className="row align-items-center pt" id="section1">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <img src={Product1} alt="" />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3>Servo Stabilizer</h3>
                    <p>
                      Servo stabilizers are designed and manufactured with the
                      latest technology and stringent quality measures to
                      safeguard all types of electrical appliances from crucial
                      voltage fluctuations. It does never be a pay, when it
                      comes, your valuable equipment shocks you with a breakdown
                      pay.
                    </p>
                    <p>
                      Each Electrical/Electronic/Electromechanical equipment
                      have designed for a particular Working voltage. A high
                      fluctuation in AC supply is a common phenomenon in the
                      whole country. The fluctuation range is varying one place
                      to another place depending on load ON/OFF. (In India
                      designed single phase equipment 220V/230V/240V, 3 Phase
                      Equipment 380V/400V/415V with 50HZ, But US/Japan Single
                      Phase 110V/110V/120V, 3 Phase 200V/220V with 60HZ).
                    </p>
                    <p>
                      Constant voltage is very important for the better
                      Efficiency, safety, and long life of equipment and
                      appliances, Some Equipments can withstand plus or Minus
                      20% or10% or 5% or 2% Of rated Voltage. It is Depended on
                      purely Equipment Manufacture
                    </p>
                  </div>
                </div>
                <div className="row align-items-center pt" id="section2">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3>Electrical Transformer</h3>
                    <p>
                      Electrical transformers are machines that transfer
                      electricity from one circuit to another with changing
                      voltage level but no frequency change. Today, they are
                      designed to use AC supply, which means that fluctuation in
                      supply voltage is impacted by the fluctuation in the
                      current. So, an increase in current will bring about an
                      increase in the voltage and vice versa.
                    </p>
                    <p>
                      Transformers help improve safety and efficiency of power
                      systems by raising and lowering voltage levels as and when
                      needed. They are used in a wide range of residential and
                      industrial applications, primarily and perhaps most
                      importantly in the distribution and regulation of power
                      across long distances.
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <img src={Product2} alt="" />
                  </div>
                </div>
                <div className="row align-items-center pt" id="section3">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <img src={Product3} alt="" />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3>Invertors</h3>
                    <p>
                      Inverters are your saviour from time consuming power cuts
                      and the solution to all your energy requirements! One of
                      the greatest inventions for Indian households and
                      Industries worldwide. It is a device that converts direct
                      current to alternating current, it works as a power
                      adapter. These are used to power electrical appliances in
                      case of a power cut.
                    </p>
                    <p>
                      Not only is the work being shifted indoors but thanks to
                      the Atma Nirbhar movement, so many new industries, start
                      ups and what not are springing up which demand a huge lot
                      of power supply. But occasional or sometimes even daily
                      maintenance power cuts cause a great distress to the
                      working of these. Inverters are extremely called for such
                      situations!
                    </p>
                    <p>
                      In current times, everyone might be in need of inverters,
                      but not everyone has the knowledge to make the right
                      decision regarding which inverter to buy. So here are a
                      few key points that can help you to make sound decisions
                      regarding your purchase!
                    </p>
                  </div>
                </div>
                <div className="row align-items-center pt" id="section4">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3>Ups</h3>
                    <p>
                      Given the enormous energy consumption in today's world -
                      due to the rapid growth of connected devices (IoT) and
                      smart industry processes - it is essential to develop more
                      energy-efficient and cost-effective power supplies,
                      especially when it comes to uninterruptible power supplies
                      (UPS). Online UPS systems provide uninterrupted power to
                      critical loads that require the highest level of
                      protection. They are commonly used in telecommunication,
                      datacenters, hospitals, and certain commercial or
                      industrial facilities.
                    </p>
                    <p>
                      The required level of protection is achieved through
                      double conversion. Double conversion means that the power
                      is converted twice: in normal operation, a rectifier
                      converts the input power from AC to DC, and feeds it to an
                      output inverter. The output inverter then converts the
                      power back to AC. Since the UPS essentially decouples the
                      load from the grid, it ensures voltage and frequency
                      stability. In case of grid failure, it provides
                      instantaneous back-up power via the battery until a
                      generator set is brought online. Various bypass structures
                      allow for maintenance during operation, improve overload
                      capability, or provide significant loss reduction.
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <img src={Product4} alt="" />
                  </div>
                </div>
                <div className="row align-items-center pt">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <img src={Product5} alt="" />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3>Batteries</h3>
                    <p>
                      A power supply is an electrical device that supplies
                      electric power to an electrical load. The main purpose of
                      a power supply is to convert electric current from a
                      source to the correct voltage, current, and frequency to
                      power the load. As a result, power supplies are sometimes
                      referred to as electric power converters. Some power
                      supplies are separate standalone pieces of equipment,
                      while others are built into the load appliances that they
                      power. Examples of the latter include power supplies found
                      in desktop computers and consumer electronics devices.
                      Other functions that power supplies may perform include
                      limiting the current drawn by the load to safe levels,
                      shutting off the current in the event of an electrical
                      fault, power conditioning to prevent electronic noise or
                      voltage surges on the input from reaching the load,
                      power-factor correction, and storing energy so it can
                      continue to power the load in the event of a temporary
                      interruption in the source power (uninterruptible power
                      supply).
                    </p>
                    <p>
                      All power supplies have a power input connection, which
                      receives energy in the form of electric current from a
                      source, and one or more power output or rail connections
                      that deliver current to the load. The source power may
                      come from the electric power grid, such as an electrical
                      outlet, energy storage devices such as batteries or fuel
                      cells, generators or alternators, solar power converters,
                      or another power supply. The input and output are usually
                      hardwired circuit connections, though some power supplies
                      employ wireless energy transfer to power their loads
                      without wired connections. Some power supplies have other
                      types of inputs and outputs as well, for functions such as
                      external monitoring and control F
                    </p>
                  </div>
                </div>
                <div className="row align-items-center pt">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3>Online UPS</h3>
                    <p>
                      Given the enormous energy consumption in today's world -
                      due to the rapid growth of connected devices (IoT) and
                      smart industry processes - it is essential to develop more
                      energy-efficient and cost-effective power supplies,
                      especially when it comes to uninterruptible power supplies
                      (UPS). Online UPS systems provide uninterrupted power to
                      critical loads that require the highest level of
                      protection. They are commonly used in telecommunication,
                      datacenters, hospitals, and certain commercial or
                      industrial facilities.
                    </p>
                    <p>
                      The required level of protection is achieved through
                      double conversion. Double conversion means that the power
                      is converted twice: in normal operation, a rectifier
                      converts the input power from AC to DC, and feeds it to an
                      output inverter. The output inverter then converts the
                      power back to AC. Since the UPS essentially decouples the
                      load from the grid, it ensures voltage and frequency
                      stability. In case of grid failure, it provides
                      instantaneous back-up power via the battery until a
                      generator set is brought online. Various bypass structures
                      allow for maintenance during operation, improve overload
                      capability, or provide significant loss reduction.
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <img src={Product6} alt="" />
                  </div>
                </div>
                <div className="row align-items-center pt">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <img src={Product7} alt="" />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3>Lighting solutions & LED Lights</h3>
                    <p>
                      The LED popularity behind this growth rate has arisen for
                      several good reasons. Their most obvious and well-known
                      properties are their high electrical efficiency and long
                      maintenance/replacement intervals. This means that,
                      compared with incandescent types, they allow a much
                      smaller carbon footprint together with a significantly
                      reduced cost of ownership. This is especially true in
                      large buildings with high ceilings, where the labour
                      needed for luminaire replacement, as well as the
                      luminaires themselves, are significant cost factors.
                    </p>
                    <p>
                      They are also attractive because of their amenity to
                      Internet of Things (IoT) – type lighting control. LEDs
                      light instantly on demand, and are controllable for
                      brightness and colour. Their light output can be directed
                      as required, rather than incandescent radiation, which is
                      omnidirectional and must be directed by reflectors.
                    </p>
                    <p>
                      However, there’s another key aspect of LED technology that
                      all lighting system designers and installers must allow
                      for. LED stands for Light Emitting Diode; LEDs are
                      semiconductor devices, just like transistors or other
                      diode types, but with the added ability to convert
                      electricity into light energy. This means that they
                      operate on low DC voltages, and cannot be plugged directly
                      into an AC mains supply like an incandescent light bulb
                    </p>
                  </div>
                </div>
                <div className="row align-items-center pt">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3>Solar products</h3>
                    <p>
                      Every solar system collects energy from the sun. A solar
                      system does not create the energy, but instead converts
                      energy from the sun into electricity your home can use.
                      That means whenever the sun is shining, your panels can
                      generate energy. Even on cloudy days, your panels can
                      still generate electricity thanks to the sun's rays
                      breaking through the clouds.
                    </p>
                    <p>
                      Your solar panels are what absorb the sun's energy and
                      convert it into electricity. The panels are made of
                      silicon, a semi-conductive element that generates DC
                      (direct current) electricity when sunlight touches it. The
                      amount of electricity a panel can generate depends on many
                      factors, including but not limited to, the type of panel,
                      placement of panel, time of day, and temperature. The best
                      way to determine the amount of energy a panel can produce
                      is by looking at the efficiency rating. Efficiency is
                      defined as the amount of power produced by the panel per
                      square meter (m2) of sunlight at Standard Testing
                      Conditions (STC)*. Basically, the more efficient a solar
                      panel is the greater potential for more energy production
                      in a given footprint. The average efficiency of solar
                      panels falls between the 17 to 19% efficiency range.**
                    </p>
                    <p>
                      The electricity that is generated from your solar panels
                      is DC electricity. However, the grid, and appliances in
                      your home, run on AC (Alternating Current) electricity.
                      Simply put, the inverter takes the DC electricity
                      collected from your panels, and converts it into AC
                      electricity so you can power your home. There are two
                      common types of inverters: string inverters and
                      microinverters. A string inverter is mounted to the side
                      of your home, and it collects all of the electricity
                      generated by your system in one place. Microinverters are
                      small components that are attached to the underside of
                      each individual solar panel; which allows DC to be
                      converted to AC right at the panel.{" "}
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <img src={Product8} alt="" />
                  </div>
                </div>
              </div>
            </div>
            {/* Product deatils Section End */}
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
}
