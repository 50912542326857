import "../App.css";
import icon from "../Images/Comman/Footer-icon.png";
import ScanMe from "../Images/Comman/Scan Me.png";
import { NavLink } from "react-router-dom";

export default function Footer() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <div className="Footer cw">
      <div className="footer-head pb">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12">
              <img src={icon} alt="" />

              <div className="pt">
                <img
                  style={{
                    margin: "10px auto",
                  }}
                  src={ScanMe}
                  alt=""
                />

                <h6 className="tac pt">Scan Me To Get loaction</h6>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6">
              <h5>
                <NavLink
                  class="nav-link"
                  onClick={scrollToTop}
                  to="/Products"
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    fontWeight: "400",
                    fontSize: "18px",
                    lineHeight: "22px",
                  }}
                >
                  Products
                </NavLink>
              </h5>
              <p>
                <NavLink
                  class="nav-link"
                  onClick={scrollToTop}
                  to="/ServoStabilizer"
                >
                  Servo Stabilizer
                </NavLink>
              </p>
              <p>
                <NavLink
                  class="nav-link"
                  onClick={scrollToTop}
                  to="/ElectricalTransformer"
                >
                  Electrical Transformer
                </NavLink>
              </p>
              <p>
                <NavLink class="nav-link" onClick={scrollToTop} to="/UPS">
                  UPS
                </NavLink>
              </p>
              <p>
                <NavLink class="nav-link" onClick={scrollToTop} to="/Invertors">
                  Invertors
                </NavLink>
              </p>
              <p>
                <NavLink class="nav-link" onClick={scrollToTop} to="/Batteries">
                  Batteries
                </NavLink>
              </p>
              <p>
                <NavLink class="nav-link" onClick={scrollToTop} to="/OnlineUPS">
                  Online UPS
                </NavLink>
              </p>
              <p>
                <NavLink
                  class="nav-link"
                  onClick={scrollToTop}
                  to="/LightingSolutions"
                >
                  Lighting Solutions
                </NavLink>
              </p>
              <p>
                <NavLink
                  class="nav-link"
                  onClick={scrollToTop}
                  to="/SolarProducts"
                >
                  Solar Products
                </NavLink>
              </p>
            </div>
            <div className="col-lg-2 col-md-3 col-6">
              <h5>Quick Links</h5>
              <p>
                <NavLink class="nav-link" onClick={scrollToTop} to="/">
                  Home
                </NavLink>
              </p>
              <p>
                <NavLink class="nav-link" onClick={scrollToTop} to="/Product">
                  Product
                </NavLink>
              </p>
              <p>
                <NavLink class="nav-link" onClick={scrollToTop} to="/Services">
                  Services
                </NavLink>
              </p>
              <p>
                <NavLink class="nav-link" onClick={scrollToTop} to="/AboutUs">
                  About Us
                </NavLink>
              </p>
              <p>
                <NavLink class="nav-link" onClick={scrollToTop} to="/ContactUs">
                  Contact Us
                </NavLink>
              </p>
            </div>
            <div className="col-lg-2 col-md-4 col-12">
              <h5>Terms and Policy</h5>
              <p>
                <NavLink
                  class="nav-link"
                  onClick={scrollToTop}
                  to="/Termsandconditions"
                >
                  Terms and Conditions
                </NavLink>
              </p>
              <p>
                <NavLink class="nav-link" onClick={scrollToTop} to="/Sitemap">
                  Privacy Policy
                </NavLink>
              </p>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <h5>Contact Us</h5>
              <p>
                <a href="https://goo.gl/maps/Zas1Ve7JbUZ5imtm9">
                  New 37, 12/1, Nethaji 6th Street, <br /> Lakshmipuram,
                  Thiruvanmiyur,
                  <br />
                  Chennai - 600 041.
                </a>
              </p>
              <div className="footer-ph-with-mail">
                <p className="cw">
                  <i class="fa-solid fa-envelope"></i>&nbsp;
                  <a href="mailto:pgsrajendra@gmail.com">
                    pgsrajendra@gmail.com
                  </a>
                </p>
                &nbsp;&nbsp;&nbsp;&nbsp; <br />
                <p className="cw">
                  &#9742;
                  <a href="tel: +044-48606399"> 044-48606399</a>,&nbsp;{" "}
                  <a href="tel:+9198410 80594">98410 80594</a>
                </p>
              </div>
              {/* Social Icon Section Start  */}
              <div class="social-media">
                <a href="#">
                  <i class="fab fa-facebook"></i>
                </a>
                <a href="#">
                  <i class="fab fa-twitter"></i>
                </a>
                <a href="#">
                  <i class="fa-brands fa-whatsapp"></i>
                </a>
                <a href="#">
                  <i class="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copy-rights">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 pg">
              <p>
                Copyright ©&nbsp;
                <a
                  href="https://www.eximho.com/"
                  target="_blank"
                  style={{
                    color: "#fff",
                    fontWeight: "600",
                    textDecoration: "none",
                  }}
                >
                  <b>Power Guard System</b> 2024. All Right Reserved.
                </a>
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 epl">
              <p>
                Powered By{" "}
                <a
                  href="https://codenest.in/"
                  target="_blank"
                  style={{
                    color: "#fff",
                    fontWeight: "600",
                    textDecoration: "none",
                  }}
                >
                  <b>Code Nest Technologies</b>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
