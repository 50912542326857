import "../App.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import React, { useState, useEffect } from "react";
import ContactUs2 from "../Images/ContactUs/ContactUs2.png";

export default function ContactUs() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    address: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    address: "",
  });

  const [phoneNumber, setPhoneNumber] = useState("");
  const [valid, setValid] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
    const inputValue = e.target.value;
    const numbersOnly = /^\d+$/;

    if (inputValue === "" || numbersOnly.test(inputValue)) {
      setValid(true);
      setPhoneNumber(inputValue);
    } else {
      setValid(false);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = {};

    // Validate name
    if (formData.name.trim() === "") {
      errors.name = "Please Enter Your Name is required !";
    }
    // Validate address
    if (formData.address.trim() === "") {
      errors.address = "Please Enter Address is required !";
    }

    // Validate phone
    if (formData.phone.trim() === "") {
      errors.phone = "Please Enter Your Phone Number is required !";
    } else if (!/\S+@\S+\.\S+/.test(formData.phone)) {
      errors.phone = "Please Enter Your Phone Number is invalid !";
    }
    // Validate message
    if (formData.name.trim() === "") {
      errors.message = "Message is required !";
    }

    // Validate email
    if (formData.email.trim() === "") {
      errors.email = "Please Enter Your Email ID is required !";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Please Enter Your Email ID is invalid !";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      // Form submission logic here
      console.log("Form submitted successfully");
    }
  };
  return (
    <div className="ContactUs">
      {loading ? (
        <div className="loader-container">
          <div class="loading-spinner"></div>
        </div>
      ) : (
        <div className="navbar-footer">
          <Navbar />
          <div className="ContactUsx-pages bg-color-w f-m-b">
            <div className="padding-top"></div>
            {/* ContactUs Home Banner Start */}
            <div className="ContactUs-home-banner">
              <div className="container">
                <h4 className="home-header">Contact Us</h4>
              </div>
            </div>
            {/* ContactUs Home Banner End */}

            {/* Location Section Start */}
            <div className="location">
              <div className="container-fluid">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.6629345701986!2d80.26008607410344!3d12.993397514401952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5261686165a691%3A0x5da79c56039721fc!2sPower%20Guard%20Systems!5e0!3m2!1sen!2sin!4v1687756326399!5m2!1sen!2sin"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>

              {/* Quickly Contact Section Start */}
              <div className="Quickly-Contact">
                <div className="container">
                  <h2 className="blue-header tac pt">Quickly Contact</h2>
                  <p className="header-para pt">
                    We take great pride in everything that we do, control over
                    products allows us to ensure our customers receive the best
                    quality service.
                  </p>
                  <div className="row pt aic">
                    <div className="col-lg-6 d-flex justify-content-center validate-form">
                      <form
                        onSubmit={handleSubmit}
                        id="contactForm"
                        className="pt pb"
                      >
                        <div>
                          <input
                            style={{
                              width: "485px",
                              height: "45px",
                              marginBottom: "20px",
                              paddingLeft: "20px",
                            }}
                            type="text"
                            id="name"
                            placeholder="Enter Your Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                          />
                          {formErrors.name && (
                            <p
                              className="error"
                              style={{
                                color: "red",
                                textAlign: "left",
                                paddingLeft: "0%",
                                marginTop: "-20px",
                                marginBottom: "20px",
                                fontSize: "14px",
                                fontFamily: "auto",
                              }}
                            >
                              {formErrors.name}
                            </p>
                          )}
                        </div>
                        <div>
                          <input
                            style={{
                              width: "485px",
                              height: "45px",
                              marginBottom: "20px",
                              paddingLeft: "20px",
                            }}
                            type="email"
                            id="email"
                            placeholder="Enter Your Email ID"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                          />
                          {formErrors.email && (
                            <p
                              className="error"
                              style={{
                                color: "red",
                                textAlign: "left",
                                paddingLeft: "0%",
                                marginTop: "-20px",
                                marginBottom: "20px",
                                fontSize: "14px",
                                fontFamily: "auto",
                              }}
                            >
                              {formErrors.email}
                            </p>
                          )}
                        </div>
                        <div>
                          <input
                            style={{
                              width: "485px",
                              height: "45px",
                              marginBottom: "20px",
                              paddingLeft: "20px",
                            }}
                            type="text"
                            maxLength={10}
                            placeholder="Enter Your Phone Number"
                            value={phoneNumber}
                            onChange={handleChange}
                          />
                          {!valid && (
                            <p
                              style={{
                                color: "red",
                                textAlign: "left",
                                paddingLeft: "0%",
                                marginTop: "-20px",
                                marginBottom: "20px",
                                fontSize: "14px",
                                fontFamily: "auto",
                              }}
                            >
                              Please enter a valid phone number.
                            </p>
                          )}
                          {formErrors.phone && (
                            <p
                              className="error"
                              style={{
                                color: "red",
                                textAlign: "left",
                                paddingLeft: "0%",
                                marginTop: "-20px",
                                marginBottom: "20px",
                                fontSize: "14px",
                                fontFamily: "auto",
                              }}
                            >
                              {formErrors.phone}
                            </p>
                          )}
                        </div>
                        <div>
                          <input
                            style={{
                              width: "485px",
                              height: "45px",
                              marginTop: "-20px",
                              marginBottom: "20px",
                              paddingLeft: "20px",
                            }}
                            type="text"
                            placeholder="Enter Your Address"
                            id="address"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                          />
                          {formErrors.name && (
                            <p
                              className="error"
                              style={{
                                color: "red",
                                textAlign: "left",
                                paddingLeft: "0%",
                                marginTop: "-20px",
                                marginBottom: "20px",
                                fontSize: "14px",
                                fontFamily: "auto",
                              }}
                            >
                              {formErrors.address}
                            </p>
                          )}
                        </div>
                        <div>
                          <textarea
                            style={{
                              width: "485px",
                              height: "180px",
                              resize: "none",
                              marginBottom: "20px",
                              paddingTop: "10px",
                              paddingLeft: "20px",
                            }}
                            id="message"
                            name="message"
                            placeholder="Enter Your Message"
                            value={formData.message}
                            onChange={handleChange}
                          ></textarea>
                          {formErrors.message && (
                            <p
                              className="error"
                              style={{
                                color: "red",
                                textAlign: "left",
                                paddingLeft: "0%",
                                marginTop: "-20px",
                                marginBottom: "20px",
                                fontSize: "14px",
                                fontFamily: "auto",
                              }}
                            >
                              {formErrors.message}
                            </p>
                          )}
                        </div>
                        <div className="pb pt message-button">
                          <button
                            style={{
                              width: "485px",
                              height: "40px",
                              background: "#121085",
                              borderRadius: "2px",
                              color: "#fff",
                              fontFamily: "Lato",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "18px",
                              lineHeight: "22px",
                            }}
                            className="Submit"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="col-lg-6 address">
                      <p>
                        We take great pride in everything that we do, control
                        over products allows us to ensure our customers receive
                        the best quality service.
                      </p>
                      <div className="address-content">
                        <div className="address-section">
                          <div className="address-location">
                            <i class="fa-solid fa-location-dot"></i>
                            <p>
                              New 37, 12/1, Nethaji 6th Street, Lakshmipuram,
                              Thiruvanmiyur, Chennai - 600 041.
                            </p>
                          </div>

                          <div className="ph-with-mail-address ">
                            <p>
                              <i class="fa-solid fa-phone"></i>
                              <a href="tel: +044-48606399"> 044-48606399

</a>
                              ,&nbsp;
                              <a href="tel:+9198410 80594">98410 80594</a>
                            </p>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <p>
                              <i class="fa-solid fa-envelope"></i>&nbsp;
                              <a href="mailto:pgsrajendra@gmail.com">
                                pgsrajendra@gmail.com
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Quickly Contact Section End */}
            </div>
            {/* Location Section End */}

            {/* Our Associations Section Start */}
            <div className="Our-Associations pb">
              <div className="container">
                <h2 className="blue-header tac pt pb">Our Associations</h2>
                <img src={ContactUs2} alt="" />
              </div>
            </div>
            {/* Our Associations Section End */}
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
}
