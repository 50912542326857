import "../../App.css";
import Navbar from "../Navbar";
import Lightning3 from "../../Images/Product/Lightning/Lightning3.png";
import Lightning4 from "../../Images/Product/Lightning/Lightning4.png";
import Lightning5 from "../../Images/Product/Lightning/Lightning5.png";
import Lightning6 from "../../Images/Product/Lightning/Lightning6.png";

import Application1 from "../../Images/Product/Application1.png";
import Application2 from "../../Images/Product/Application2.png";
import Application3 from "../../Images/Product/Application3.png";
import Application4 from "../../Images/Product/Application4.png";
import Application5 from "../../Images/Product/Application5.png";

import Footer from "../Footer";
import React, { useState, useEffect } from "react";

export default function LightingSolutions() {
  const [activeImage, setActiveImage] = useState(null);

  const handleImageClick = (index) => {
    setActiveImage(index);
  };
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  return (
    <div className="LightingSolutions">
      {loading ? (
        <div className="loader-container">
          <div class="loading-spinner"></div>
        </div>
      ) : (
        <div className="navbar-footer">
          <Navbar />
          <div className="LightingSolutions-page feature-img pb bg-color-w f-m-b">
            <div className="padding-top"></div>
            {/* LightingSolutions Home Banner Start */}
            <div className="LightingSolutions-home-banner">
              <h4 className="home-header">Lighting Solutions & LED Lights</h4>
            </div>
            {/* LightingSolutions Home Banner End */}
            {/* AboutUs Section Start */}
            <div className="about-section tac pt mb">
              <div className="container">
                <h2 className="blue-header">Powering Your Equipment</h2>
                <div className="row Mission align-items-center">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div class="container mt-5">
                      <div class="carousel-container position-relative row align-items-center">
                        <div className="col-lg-2 col-md-2 col-2">
                          <div
                            id="carousel-thumbs"
                            class="carousel slide"
                            data-ride="carousel"
                          >
                            <div class="carousel-inner small-image">
                              <div class="carousel-item active">
                                <div class=" mx-0 justify-content-around">
                                  <div
                                    id="carousel-selector-0"
                                    class="thumb col-4 col-sm-2 px-1 py-2 selected"
                                    data-target="#myCarousel"
                                    data-slide-to="0"
                                  >
                                    <img
                                      src={Lightning3}
                                      class="img-fluid"
                                      className={
                                        activeImage === 0 ? "active" : ""
                                      }
                                      onClick={() => handleImageClick(0)}
                                      alt="Lightning3"
                                    />
                                  </div>
                                  <div
                                    id="carousel-selector-1"
                                    class="thumb col-4 col-sm-2 px-1 py-2"
                                    data-target="#myCarousel"
                                    data-slide-to="1"
                                  >
                                    <img
                                      src={Lightning4}
                                      class="img-fluid"
                                      className={
                                        activeImage === 1 ? "active" : ""
                                      }
                                      onClick={() => handleImageClick(1)}
                                      alt="Lightning4"
                                    />
                                  </div>
                                  <div
                                    id="carousel-selector-2"
                                    class="thumb col-4 col-sm-2 px-1 py-2"
                                    data-target="#myCarousel"
                                    data-slide-to="2"
                                  >
                                    <img
                                      src={Lightning5}
                                      class="img-fluid"
                                      className={
                                        activeImage === 2 ? "active" : ""
                                      }
                                      onClick={() => handleImageClick(2)}
                                      alt="Lightning5"
                                    />
                                  </div>
                                  <div
                                    id="carousel-selector-3"
                                    class="thumb col-4 col-sm-2 px-1 py-2"
                                    data-target="#myCarousel"
                                    data-slide-to="3"
                                  >
                                    <img
                                      src={Lightning6}
                                      class="img-fluid"
                                      className={
                                        activeImage === 3 ? "active" : ""
                                      }
                                      onClick={() => handleImageClick(3)}
                                      alt="Lightning6"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-10 col-md-10 col-10">
                          <div
                            id="myCarousel"
                            class="carousel slide"
                            data-ride="carousel"
                          >
                            <div class="carousel-inner top-four-img">
                              <div
                                class="carousel-item active"
                                data-slide-number="0"
                              >
                                <img
                                  src={Lightning3}
                                  class="d-block w-100 h-75"
                                  alt="..."
                                  data-remote="https://source.unsplash.com/Pn6iimgM-wo/"
                                  data-type="image"
                                  data-toggle="lightbox"
                                  data-gallery="example-gallery"
                                />
                              </div>
                              <div class="carousel-item" data-slide-number="1">
                                <img
                                  src={Lightning4}
                                  class="d-block w-100 h-75"
                                  alt="..."
                                  data-remote="https://source.unsplash.com/tXqVe7oO-go/"
                                  data-type="image"
                                  data-toggle="lightbox"
                                  data-gallery="example-gallery"
                                />
                              </div>
                              <div class="carousel-item" data-slide-number="2">
                                <img
                                  src={Lightning5}
                                  class="d-block w-100 h-75"
                                  alt="..."
                                  data-remote="https://source.unsplash.com/qlYQb7B9vog/"
                                  data-type="image"
                                  data-toggle="lightbox"
                                  data-gallery="example-gallery"
                                />
                              </div>
                              <div class="carousel-item" data-slide-number="3">
                                <img
                                  src={Lightning6}
                                  class="d-block w-100 h-75"
                                  alt="..."
                                  data-remote="https://source.unsplash.com/QfEfkWk1Uhk/"
                                  data-type="image"
                                  data-toggle="lightbox"
                                  data-gallery="example-gallery"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-6 col-md-6 col-12 pt"
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    <h3>Lighting Solutions & LED Lights</h3>
                    <p
                      style={{
                        margin: "0 auto",
                        textAlign: "justify",
                      }}
                    >
                      Hykon Online UPS supplies power irrespective of whether
                      the mains power is healthy or not. Online UPS offers
                      maximum power quality enhancement to a critical load.
                      There is no transfer process involved and the inverter
                      delivers pure sine wave of constant amplitude to the load.
                      Online UPS models offered by Hykons are HT Series, Alpha
                      series, and Galaxy series.
                    </p>
                    <h3 className="pt">FEATURES</h3>
                    <ul
                      className="text-start"
                      style={{
                        margin: "0 auto",
                        width: "90%",
                        lineHeight: "32px",
                      }}
                    >
                      <li>Fully digitized microprocessor control</li>
                      <li>Wide input voltage range</li>
                      <li>LCD display</li>
                      <li>Cold start function</li>
                      <li>Advanced battery management</li>
                      <li>Short circuit & Overload protection</li>
                      <li>Remote monitoring</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* AboutUs Section End */}

            {/* Donwload Section Start */}
            <div className="Donwloadd tac">
              <div className="container">
                <h4>
                  Donwload the Online UPS brochure and Request to quote Details
                </h4>
                <div className="Request-Brochure pt">
                <button
                    type="button"
                    class="btn btn-primary"
                    className="Request"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Request Quote
                  </button>
                  <button className="Brochure">Download Brochure</button>
                </div>
              </div>
            </div>
            {/* Donwload Section End */}

            {/* Applications Section Start */}
            <div className="Applications pt tac">
              <h2 className="blue-header">Applications</h2>
              <div className="container">
                <div className="row pt">
                  <div className="col-lg-1"></div>
                  <div className="col-lg-2 col-md-4 col-4">
                    <img src={Application1} alt="" />
                  </div>
                  <div className="col-lg-2 col-md-4 col-4">
                    <img src={Application2} alt="" />
                  </div>
                  <div className="col-lg-2 col-md-4 col-4">
                    <img src={Application3} alt="" />
                  </div>
                  <div className="col-lg-2 col-md-4 col-4">
                    <img src={Application4} alt="" />
                  </div>
                  <div className="col-lg-2 col-md-4 col-4">
                    <img src={Application5} alt="" />
                  </div>
                  <div className="col-lg-1"></div>
                </div>
              </div>
            </div>
            {/* Applications Section End */}
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
}
