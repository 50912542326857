import "../App.css";
import Navbar from "./Navbar";
import Services2 from "../Images/Services/Services2.png";
import Services3 from "../Images/Services/Services3.png";
import Services4 from "../Images/Services/Services4.png";
import Footer from "./Footer";
import React, { useState, useEffect } from "react";
export default function Services() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  return (
    <div className="Services ">
      {loading ? (
        <div className="loader-container">
          <div class="loading-spinner"></div>
        </div>
      ) : (
        <div className="navbar-footer">
          <Navbar />
          <div className="Services-page pb bg-color-w f-m-b">
            <div className="padding-top"></div>
            {/* Services Home Banner Start */}
            <div className="Services-home-banner">
              <h4 className="home-header">Services</h4>
            </div>
            {/* Services Home Banner End */}

            {/* Services Section Start */}
            <div className="Services-section tac pt">
              <div className="container">
                <h2 className="blue-header">Our Services</h2>
                <p className="header-para pt">
                  PowerOne products have uses in different industries and
                  sectors of the economy. One must also not forget the
                  application of PowerOne products at the home-front. PowerOne
                  products can be used at the modern office, to power medical
                  equipment at hospitals, at petrol pumps and banks too!
                  PowerOne’s wide array
                </p>
                <div className="row pt aic Mission">
                  <div className="col-lg-6 p-0">
                    <img src={Services2} alt="" />
                  </div>
                  <div className="col-lg-6">
                    <h3>Electrical Contractor</h3>
                    <p>
                      PowerOne products have uses in different industries and
                      sectors of the economy. One must also not forget the
                      application of PowerOne products at the home-front.
                      PowerOne products can be used at the modern office, to
                      power medical equipment at hospitals, at petrol pumps and
                      banks too! PowerOne’s wide array of vibrant products can
                      serve as the backbone of your workplace, school or
                      college, and home. With everything from Transformers to
                      Solar Panels, PowerOne products find utility all across
                      the board.
                    </p>
                  </div>
                </div>
                <div className="row aic Vission">
                  <div className="col-lg-6 ">
                    <h3>Toll Gate Construction</h3>
                    <p>
                      PowerOne products have uses in different industries and
                      sectors of the economy. One must also not forget the
                      application of PowerOne products at the home-front.
                      PowerOne products can be used at the modern office, to
                      power medical equipment at hospitals, at petrol pumps and
                      banks too! PowerOne’s wide array of vibrant products can
                      serve as the backbone of your workplace, school or
                      college, and home. With everything from Transformers to
                      Solar Panels, PowerOne products find utility all across
                      the board.
                    </p>
                  </div>
                  <div className="col-lg-6 p-0">
                    <img src={Services3} alt="" />
                  </div>
                </div>
                <div className="row aic Mission">
                  <div className="col-lg-6 p-0">
                    <img src={Services4} alt="" />
                  </div>
                  <div className="col-lg-6 ">
                    <h3>Engineering Consultant</h3>
                    <p>
                      PowerOne products have uses in different industries and
                      sectors of the economy. One must also not forget the
                      application of PowerOne products at the home-front.
                      PowerOne products can be used at the modern office, to
                      power medical equipment at hospitals, at petrol pumps and
                      banks too! PowerOne’s wide array of vibrant products can
                      serve as the backbone of your workplace, school or
                      college, and home. With everything from Transformers to
                      Solar Panels, PowerOne products find utility all across
                      the board.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* AboutUs Section End */}
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
}
