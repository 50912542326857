import "../App.css";
import "../Responsive.css";
import React, { useState, useEffect } from "react";

import { NavLink } from "react-router-dom";
import logo from "../Images/Comman/favicon.png";
export default function Navbar() {
  const [show, setShow] = useState(false);
  const controlNavbar = () => {
    if (window.scrollY > 250) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    address: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    address: "",
  });

  const [phoneNumber, setPhoneNumber] = useState("");
  const [valid, setValid] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });

    const inputValue = e.target.value;
    const numbersOnly = /^\d+$/;
    if (inputValue === "" || numbersOnly.test(inputValue)) {
      setValid(true);
      setPhoneNumber(inputValue);
    } else {
      setValid(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = {};

    // Validate name
    if (formData.name.trim() === "") {
      errors.name = "Please Enter Your Name is required !";
    }
    // Validate address
    if (formData.address.trim() === "") {
      errors.address = "Please Enter Address is required !";
    }

    // Validate phone
    if (formData.phone.trim() === "") {
      errors.phone = "Please Enter Your Phone Number is required !";
    }

    // Validate message
    if (formData.name.trim() === "") {
      errors.message = "Message is required !";
    }

    // Validate email
    if (formData.email.trim() === "") {
      errors.email = "Please Enter Your Email ID is required !";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Please Enter Your Email ID is invalid !";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      // Form submission logic here
      console.log("Form submitted successfully");
    }
  };
  return (
    <div className="mail-with-nav">
      <div className={`activesed Navbar ${show && "hidden"}`}>
        <div className="ph-mail cw">
          <div className="container">
            <div className="ph-with-mail">
              <p>
                &#9742;
                <a href="tel: +044-48606399"> 044-48606399</a>,
              </p>
              &nbsp;
              <p>
                <a href="tel:+9198410 80594">98410 80594</a>
              </p>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <p>
                <i class="fa-solid fa-envelope"></i>&nbsp;
                <a href="mailto:pgsrajendra@gmail.com">pgsrajendra@gmail.com</a>
              </p>
            </div>
          </div>
        </div>
        <div className="Navigation bg-color">
          <div className="container">
            <nav class="navbar navbar-expand-lg">
              <div class="container-fluid">
                <NavLink to="/">
                  <img src={logo} alt="" />
                </NavLink>
                <button
                  class="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                  <ul class="navbar-nav">
                    <li class="nav-item">
                      <NavLink
                        exact
                        class="nav-link active"
                        aria-current="page"
                        onClick={scrollToTop}
                        to="/"
                        style={({ isActive, isPending }) => {
                          return {
                            // background: isActive ? "#2311b9" : "",
                            color: isActive ? "#2311b9" : "#2311b9",
                            borderBottom: isActive ? "3px solid #2311b9" : "",
                            fontWeight: isActive ? "Bold" : "",
                            transition: isActive ? "all 0.5s linear" : "",
                            // color: isPending ? "#2311b9" : "#2311b9",
                          };
                        }}
                      >
                        Home
                      </NavLink>
                    </li>
                    <li class="nav-item">
                      <NavLink
                        class="nav-link"
                        to="/AboutUs"
                        onClick={scrollToTop}
                        style={({ isActive, isPending }) => {
                          return {
                            borderBottom: isActive ? "3px solid #2311b9" : "",
                            fontWeight: isActive ? "bold" : "",
                            transition: isActive ? "all 0.5s linear" : "",
                          };
                        }}
                      >
                        About Us
                      </NavLink>
                    </li>

                    <li class="nav-item">
                      <div class="dropdown">
                        <button
                          class="btn dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          id="dropdownMenuButton"
                          data-mdb-toggle="dropdown"
                        >
                          <NavLink
                            class="nav-link"
                            aria-current="page"
                            onClick={scrollToTop}
                            to="/ServoStabilizer"
                            style={({ isActive, isPending }) => {
                              return {
                                fontWeight: isActive ? "bold" : "",
                                color: isPending ? "red" : "black",
                                borderBottom: isActive
                                  ? "3px solid #2311b9"
                                  : "",
                                transition: isActive ? "all 0.5s linear" : "",
                              };
                            }}
                          >
                            Products
                          </NavLink>
                        </button>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <li>
                            <NavLink
                              class="nav-link"
                              aria-current="page"
                              onClick={scrollToTop}
                              to="/ServoStabilizer"
                              style={({ isActive, isPending }) => {
                                return {
                                  fontWeight: isActive ? "bold" : "",
                                  color: isPending ? "red" : "black",
                                  borderBottom: isActive
                                    ? "3px solid #2311b9"
                                    : "",
                                  transition: isActive ? "all 0.5s linear" : "",
                                };
                              }}
                            >
                              Servo Stabilizer
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              class="nav-link"
                              aria-current="page"
                              onClick={scrollToTop}
                              to="/ElectricalTransformer"
                              style={({ isActive, isPending }) => {
                                return {
                                  fontWeight: isActive ? "bold" : "",
                                  color: isPending ? "red" : "black",
                                  borderBottom: isActive
                                    ? "3px solid #2311b9"
                                    : "",
                                  transition: isActive ? "all 0.5s linear" : "",
                                };
                              }}
                            >
                              Electrical Transformer
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              class="nav-link"
                              aria-current="page"
                              onClick={scrollToTop}
                              to="/UPS"
                              style={({ isActive, isPending }) => {
                                return {
                                  fontWeight: isActive ? "bold" : "",
                                  color: isPending ? "red" : "black",
                                  borderBottom: isActive
                                    ? "3px solid #2311b9"
                                    : "",
                                  transition: isActive ? "all 0.5s linear" : "",
                                };
                              }}
                            >
                              UPS
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              class="nav-link"
                              aria-current="page"
                              onClick={scrollToTop}
                              to="/Invertors"
                              style={({ isActive, isPending }) => {
                                return {
                                  fontWeight: isActive ? "bold" : "",
                                  color: isPending ? "red" : "black",
                                  borderBottom: isActive
                                    ? "3px solid #2311b9"
                                    : "",
                                  transition: isActive ? "all 0.5s linear" : "",
                                };
                              }}
                            >
                              Invertors
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              class="nav-link"
                              aria-current="page"
                              onClick={scrollToTop}
                              to="/Batteries"
                              style={({ isActive, isPending }) => {
                                return {
                                  fontWeight: isActive ? "bold" : "",
                                  color: isPending ? "red" : "black",
                                  borderBottom: isActive
                                    ? "3px solid #2311b9"
                                    : "",
                                  transition: isActive ? "all 0.5s linear" : "",
                                };
                              }}
                            >
                              Batteries
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              class="nav-link"
                              aria-current="page"
                              onClick={scrollToTop}
                              to="/OnlineUPS"
                              style={({ isActive, isPending }) => {
                                return {
                                  fontWeight: isActive ? "bold" : "",
                                  color: isPending ? "red" : "black",
                                  borderBottom: isActive
                                    ? "3px solid #2311b9"
                                    : "",
                                  transition: isActive ? "all 0.5s linear" : "",
                                };
                              }}
                            >
                              Online UPS
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              class="nav-link"
                              aria-current="page"
                              onClick={scrollToTop}
                              to="/Solarproducts"
                              style={({ isActive, isPending }) => {
                                return {
                                  fontWeight: isActive ? "bold" : "",
                                  color: isPending ? "red" : "black",
                                  borderBottom: isActive
                                    ? "3px solid #2311b9"
                                    : "",
                                  transition: isActive ? "all 0.5s linear" : "",
                                };
                              }}
                            >
                              Solar products
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              class="nav-link"
                              aria-current="page"
                              onClick={scrollToTop}
                              to="/LightingSolutions"
                              style={({ isActive, isPending }) => {
                                return {
                                  fontWeight: isActive ? "bold" : "",
                                  color: isPending ? "red" : "black",
                                  borderBottom: isActive
                                    ? "3px solid #2311b9"
                                    : "",
                                  transition: isActive ? "all 0.5s linear" : "",
                                };
                              }}
                            >
                              Lighting solutions & LED Lights
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li class="nav-item">
                      <NavLink
                        class="nav-link"
                        aria-current="page"
                        onClick={scrollToTop}
                        to="/Services"
                        style={({ isActive, isPending }) => {
                          return {
                            fontWeight: isActive ? "bold" : "",
                            color: isPending ? "red" : "black",
                            borderBottom: isActive ? "3px solid #2311b9" : "",
                            transition: isActive ? "all 0.5s linear" : "",
                          };
                        }}
                      >
                        Services
                      </NavLink>
                    </li>
                    <li class="nav-item">
                      <NavLink
                        class="nav-link"
                        aria-current="page"
                        to="/Amc"
                        href="#section1"
                        onClick={scrollToTop}
                        style={({ isActive, isPending }) => {
                          return {
                            borderBottom: isActive ? "3px solid #2311b9" : "",
                            fontWeight: isActive ? "bold" : "",
                            transition: isActive ? "all 0.5s linear" : "",
                          };
                        }}
                      >
                        AMC
                      </NavLink>
                    </li>
                    <li class="nav-item">
                      <NavLink
                        class="nav-link"
                        aria-current="page"
                        onClick={scrollToTop}
                        to="/Gallery"
                        style={({ isActive, isPending }) => {
                          return {
                            fontWeight: isActive ? "bold" : "",
                            color: isPending ? "red" : "black",
                            borderBottom: isActive ? "3px solid #2311b9" : "",
                            transition: isActive ? "all 0.5s linear" : "",
                          };
                        }}
                      >
                        Gallery
                      </NavLink>
                    </li>
                    <li class="nav-item">
                      <NavLink
                        class="nav-link"
                        onClick={scrollToTop}
                        aria-current="page"
                        to="/ContactUs"
                        style={({ isActive, isPending }) => {
                          return {
                            fontWeight: isActive ? "bold" : "",
                            color: isPending ? "red" : "black",
                            borderBottom: isActive ? "3px solid #2311b9" : "",
                            transition: isActive ? "all 0.5s linear" : "",
                          };
                        }}
                      >
                        Contact Us
                      </NavLink>
                    </li>
                    <li>
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        Enquiry
                      </button>

                      <div
                        class="modal fade"
                        id="exampleModal"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div className="container">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h1
                                  class="modal-title fs-5"
                                  id="exampleModalLabel"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  Enquiry Form
                                </h1>

                                <button
                                  type="button"
                                  class="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div class="modal-body">
                                <p
                                  style={{
                                    width: "70%",
                                    textAlign: "center",
                                    margin: " 0 auto",
                                    fontSize: "18px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Complete the form below with your enquiry and
                                  a member of the Power Guard System team will
                                  conatct you
                                </p>
                                <div className=" d-flex justify-content-center validate-form">
                                  <form
                                    onSubmit={handleSubmit}
                                    id="contactForm"
                                    className="pt pb"
                                  >
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <div>
                                          <label>Enter Your Name</label>
                                          <br />
                                          <input
                                            style={{
                                              width: "100%",
                                              height: "45px",
                                              marginBottom: "20px",
                                              paddingLeft: "20px",
                                              borderRadius: "5px",
                                              border: "1px solid #ccc",
                                            }}
                                            type="text"
                                            id="name"
                                            // placeholder="Enter Your Name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                          />
                                          {formErrors.name && (
                                            <p
                                              className="error"
                                              style={{
                                                color: "red",
                                                textAlign: "left",
                                                paddingLeft: "0%",
                                                marginTop: "-20px",
                                                marginBottom: "20px",
                                                fontSize: "14px",
                                                fontFamily: "auto",
                                              }}
                                            >
                                              {formErrors.name}
                                            </p>
                                          )}
                                        </div>
                                        <div>
                                          {" "}
                                          <label>Enter Your Email ID</label>
                                          <br />
                                          <input
                                            style={{
                                              width: "100%",
                                              height: "45px",
                                              marginBottom: "20px",
                                              paddingLeft: "20px",
                                              borderRadius: "5px",
                                              border: "1px solid #ccc",
                                            }}
                                            type="email"
                                            id="email"
                                            // placeholder="Enter Your Email ID"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                          />
                                          {formErrors.email && (
                                            <p
                                              className="error"
                                              style={{
                                                color: "red",
                                                textAlign: "left",
                                                paddingLeft: "0%",
                                                marginTop: "-20px",
                                                marginBottom: "20px",
                                                fontSize: "14px",
                                                fontFamily: "auto",
                                              }}
                                            >
                                              {formErrors.email}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div>
                                          <label>Enter Your Phone Number</label>
                                          <br />
                                          <input
                                            style={{
                                              width: "100%",
                                              height: "45px",
                                              marginBottom: "20px",
                                              paddingLeft: "20px",
                                              borderRadius: "5px",
                                              border: "1px solid #ccc",
                                            }}
                                            type="phone"
                                            id="phone"
                                            name="phone"
                                            maxLength={10}
                                            // placeholder="Enter Your Phone Number"
                                            value={phoneNumber}
                                            onChange={handleChange}
                                          />
                                          {!valid && (
                                            <p
                                              style={{
                                                color: "red",
                                                textAlign: "left",
                                                paddingLeft: "0%",
                                                marginTop: "-20px",
                                                marginBottom: "20px",
                                                fontSize: "14px",
                                                fontFamily: "auto",
                                              }}
                                            >
                                              Please enter a valid phone number.
                                            </p>
                                          )}
                                          {formErrors.phone && (
                                            <p
                                              className="error"
                                              style={{
                                                color: "red",
                                                textAlign: "left",
                                                paddingLeft: "0%",
                                                marginTop: "-20px",
                                                marginBottom: "20px",
                                                fontSize: "14px",
                                                fontFamily: "auto",
                                              }}
                                            >
                                              {formErrors.phone}
                                            </p>
                                          )}
                                        </div>
                                        <div>
                                          <label>Enter Your Address</label>
                                          <br />
                                          <input
                                            style={{
                                              width: "100%",
                                              height: "45px",
                                              // marginTop: "-20px",
                                              marginBottom: "20px",
                                              paddingLeft: "20px",
                                              borderRadius: "5px",
                                              border: "1px solid #ccc",
                                            }}
                                            type="text"
                                            // placeholder="Enter Your Address"
                                            id="address"
                                            name="address"
                                            value={formData.address}
                                            onChange={handleChange}
                                          />
                                          {formErrors.name && (
                                            <p
                                              className="error"
                                              style={{
                                                color: "red",
                                                textAlign: "left",
                                                paddingLeft: "0%",
                                                marginTop: "-20px",
                                                marginBottom: "20px",
                                                fontSize: "14px",
                                                fontFamily: "auto",
                                              }}
                                            >
                                              {formErrors.address}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div>
                                      <label>Enter Your Message</label>
                                      <br />
                                      <textarea
                                        style={{
                                          width: "100%",
                                          height: "180px",
                                          resize: "none",
                                          marginBottom: "20px",
                                          paddingTop: "10px",
                                          paddingLeft: "20px",
                                          borderRadius: "5px",
                                          border: "1px solid #ccc",
                                        }}
                                        id="message"
                                        name="message"
                                        // placeholder="Enter Your Message"
                                        value={formData.message}
                                        onChange={handleChange}
                                      ></textarea>
                                      {formErrors.message && (
                                        <p
                                          className="error"
                                          style={{
                                            color: "red",
                                            textAlign: "left",
                                            paddingLeft: "0%",
                                            marginTop: "-20px",
                                            marginBottom: "20px",
                                            fontSize: "14px",
                                            fontFamily: "auto",
                                          }}
                                        >
                                          {formErrors.message}
                                        </p>
                                      )}
                                    </div>
                                    <div className="pb pt message-button">
                                      <button
                                        style={{
                                          width: "100%",
                                          height: "40px",
                                          background: "#121085",
                                          borderRadius: "2px",
                                          color: "#fff",
                                          fontFamily: "Lato",
                                          fontStyle: "normal",
                                          fontWeight: "500",
                                          fontSize: "18px",
                                          lineHeight: "22px",
                                        }}
                                        className="Submit"
                                        type="submit"
                                        id="button"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
