import "../../App.css";
import Navbar from "../Navbar";
import Amc2 from "../../Images/Product/Amc/Amc2.png";
import Amc3 from "../../Images/Product/Amc/Amc3.png";
import Amc4 from "../../Images/Product/Amc/Amc4.png";
import Footer from "../Footer";
import React, { useState, useEffect } from "react";

export default function Amc() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  return (
    <div className="Amc">
      {loading ? (
        <div className="loader-container">
          <div class="loading-spinner"></div>
        </div>
      ) : (
        <div className="navbar-footer">
          <Navbar />
          <div className="Amc-page pb bg-color-w f-m-b">
            <div className="padding-top"></div>
            {/* Amc Home Banner Start */}
            <div className="Amc-home-banner">
              <h4 className="home-header">AMC & REPAIRS</h4>
            </div>
            {/* Amc Home Banner End */}

            {/* Amc Section Start */}
            <div className="Amc-section tac pt">
              <div className="container">
                <h2 className="blue-header">
                  Annual Maintenance Contract (AMC)
                </h2>
              </div>

              <div className="amc-section-1">
                <div className="container">
                  <div className="row aic Mission">
                    <div className="col-lg-6">
                      <h3>Why Choose Annual Maintenance Contract (AMC)</h3>
                      <p>
                        We undertake UPS maintenance India of almost all MNC
                        brands like DB/Emerson/ Numeric/APC. We are specialised
                        in UPS repair service including repairing of control
                        cards at chip level to ensure system uptime is achieved
                        at lower costs. We undertake UPS maintenance India of
                        almost all MNC brands like DB/Emerson/ Numeric/APC. We
                        are specialised in UPS repair service including
                        repairing of control cards at chip level to ensure
                        system uptime is achieved at lower costs.
                      </p>
                      {/* <button>Contact Us</button> */}
                    </div>
                    <div className="col-lg-6">
                      <img src={Amc4} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="amc-section-2"
                style={{
                  background: "#f3f8ff",
                  marginTop: "30px",
                }}
              >
                <div className="container">
                  <div className="row aic Mission">
                    <div
                      className="col-lg-6"
                      style={{
                        padding: "0",
                      }}
                    >
                      <img src={Amc2} alt="" />
                    </div>
                    <div className="col-lg-6 ">
                      <h3>Annual Maintenance Contract (AMC)</h3>
                      <p>
                        Enertech’s AMC for UPS repair service safeguards you
                        from incurring unexpected expenditure in the event of a
                        product break down. The AMC takes care of annual UPS
                        maintenance India and periodical replacements of parts.
                        It also ensures a longer life and trouble free operation
                        for your product. Maximum uptime and quick service
                        ensures higher returns and optimum use of the products.
                        We offer customised AMC for UPS that suits every
                        customer’s convenience and needs. A standard process to
                        obtain an AMC for UPS is as follows:
                      </p>
                      {/* <button>Contact Us</button> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="amc-section-3">
                <div className="container">
                  <div className="row aic Vission">
                    <div className="col-lg-6 ">
                      <h3>Repairs Annual Maintenance Contract (AMC)</h3>
                      <p>
                        We undertake UPS maintenance India of almost all MNC
                        brands like DB/Emerson/ Numeric/APC. We are specialised
                        in UPS repair service including repairing of control
                        cards at chip level to ensure system uptime is achieved
                        at lower costs. We undertake UPS maintenance India of
                        almost all MNC brands like DB/Emerson/ Numeric/APC. We
                        are specialised in UPS repair service including
                        repairing of control cards at chip level to ensure
                        system uptime is achieved at lower costs.
                      </p>
                      {/* <button>Contact Us</button> */}
                    </div>
                    <div
                      className="col-lg-6"
                      style={{
                        padding: "0",
                      }}
                    >
                      <img src={Amc3} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Amc Section End */}
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
}
